import React, { useState } from "react";
import { data } from "~data";
import { Card, Search, ThemeSelect } from "~view";

interface Props {}

export const Content = (props: Props) => {
  const [legoSets, setLegoSets] = useState(data);

  const handleChange = ({ target }: { target: HTMLInputElement }) => {
    setLegoSets(filterSets(data, target.value));
  };

  const onThemeSelect = (theme: string) => {
    if (theme === "All") {
      setLegoSets(data);
    } else {
      setLegoSets(data.filter((item) => item.theme === theme));
    }
  };

  const filterSets = (data: any, searchTerm: string) => {
    if (!searchTerm) {
      return data;
    }
    return data.filter((item: any) =>
      item.sets.some((set: any) =>
        set.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  return (
    <React.Fragment>
      <div className="options">
        <Search handleChange={handleChange} />
        <ThemeSelect onThemeSelect={onThemeSelect} />
      </div>
      <div className="cards">
        {legoSets.map((item: any) => {
          return <Card key={item.id} item={item} />;
        })}
      </div>
    </React.Fragment>
  );
};
