import "./search.css";

export const Search = (props: {handleChange: ({ target }: { target: HTMLInputElement; }) => void}) => {
  const onInputClick = ({ target }: {target: HTMLInputElement}) =>
    target && target.select && target.select();

  return (
    <div className="search">
      <input
        className="search__input"
        type="search"
        placeholder="Search for set number, name, theme..."
        onChange={props.handleChange}
        onFocus={onInputClick}
      />
    </div>
  );
};
