export const Header = () => (
  <header>
    <a href="/">
      <h1 className="title">Brick Spy NZ</h1>
    </a>
    <p>Find the best Lego deals in New Zealand!</p>
    <a
      href="https://forms.gle/XAA1CNAToBJcHuf36"
      target="_blank"
      rel="noopener noreferrer"
    >
      We'd love to hear your feedback!
    </a>
    <hr />
  </header>
);
