import React from "react";
import Select from "react-select";
import { themes as options } from "~data";

interface Props {
  onThemeSelect: (value: string) => void;
}

interface State {

}

export class ThemeSelect extends React.Component<Props, State> {
  state = {
    selectedOption: null,
    themeOptions: options,
  };

  handleChange = (selectedOption: { value: any; }) => {
    this.setState({ selectedOption });
    this.props.onThemeSelect(selectedOption.value);
  };
  render() {
    const { selectedOption } = this.state;

    const customStyles = {
      control: (provided: any) => ({
        ...provided,
        height: "45px",
        fontSize: "1rem",
        borderRadius: "5px",
      }),
    };

    return (
      <Select
        styles={customStyles}
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.themeOptions}
        placeholder="Select a theme..."
      />
    );
  }
}
