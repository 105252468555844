import React from "react";
import "./App.css";
import { Header, Content } from "./view";

export const App = () => (
  <main className="app">
    <Header />
    <Content />
  </main>
);
