export const Card = ({ item }: {item: any}) => {
  const id = item.id;

  const title = item.sets[0].title;
  const imgUrl = item.thumbnailURL;

  // @TODO: move this currency conversion method to a service.
  item.sets.sort(
    (a:any,b: any) =>
      Number(a.price.toString().replace(/(^\$|,)/g, "")) -
      Number(b.price.toString().replace(/(^\$|,)/g, ""))
  );

  return (
    <div className="card">
      <a href={item.bricksetURL} target="_blank" rel="noopener noreferrer">
        <div className="card__image-container">
          <img loading="lazy" className="card__image" src={imgUrl} alt={`lego set: ${id}`} />
        </div>
      </a>
      <p className="card__title">{title}</p>
      <div className="card__stores">
        {item.sets.map((set:any) => (
          <p key={`${item.id}-${set.store}`}>
            <a href={set.url} target="_blank" rel="noopener noreferrer">
              {set.store}: <strong>{set.price}</strong>
            </a>
            <span className="card__store__in-stock">
              {set.inStock ? " ✅" : " ❌"}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};
